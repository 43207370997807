<template>
  <v-row class="home" justify="center">
    <v-col v-if="authUser" style="max-width: 1024px">
      Settings
    </v-col>
    <v-progress-circular v-else class="loader" :size="50" color="grey lighten-2" indeterminate></v-progress-circular>
  </v-row>
</template>

<style lang="scss" scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('auth', ['authUser']),
  },
}
</script>